import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Examples from "../components/examples"
import SEO from "../components/seo"

const Gallery = () => {
  return (
    <Layout>
      <SEO title={"Gallery"} />
      <Header /> 
      <h2>Gallery</h2>
      <div style={{ maxWidth: "70ch" }}>
        <p>The beauty of this software is that it is fully extensible! Your products can be digital, physical, single variant, multiple variants, customizable, or completely custom one-offs. Likewise, the site design can be as simple or as complicated as you want!</p>
      </div>
      <Examples />
    </Layout>
  )
}

export default Gallery