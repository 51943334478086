import React from "react"
import styled from "styled-components";

import gallery1 from "../images/gallery1.png";
import gallery2 from "../images/gallery2.png";
import gallery3 from "../images/gallery3.png";
import gallery4 from "../images/gallery4.png";

import post1 from "../images/post1.png";
import post2 from "../images/post2.png";
import post3 from "../images/post3.png";
import post4 from "../images/post4.png";

import gpx1 from "../images/gpx1.png";
import gpx2 from "../images/gpx2.png";
import gpx3 from "../images/gpx3.png";
import gpx4 from "../images/gpx4.png";

const Grid = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 20px 0;
  img { border: 1px solid #eee; }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Examples = () => (
  <div>
    <h3 style={{ marginTop: "40px" }}>Basic Store</h3>
    <p>Here's a site concept for jewelry pieces, with images and description text for each product.</p>
    <p>Notice that the ring has variants in both ring size and material type.</p>
    <Grid>
      <img src={gallery1} alt="example product" />
      <img src={gallery2} alt="example product" />
    </Grid>
    <p>Next we have both of the products added to a cart, and finally the checkout flow for customers.</p>
    <Grid>
      <img src={gallery3} alt="cart page" />
      <img src={gallery4} alt="checkout page" />
    </Grid>

    <h3 style={{ marginTop: "40px" }}>Custom Store: <a href="https://postcardgifts.com">Postcard Gifts</a></h3>
    <p>Design your own postcards, and mail them to your friends and family.</p>
    <Grid>
      <img src={post1} alt="landing page" />
      <img src={post2} alt="product page" />
      <img src={post3} alt="cart page" />
      <img src={post4} alt="checkout page" />
    </Grid>

    <h3 style={{ marginTop: "40px" }}>Custom Store: <a href="https://gpxjewelry.com">GPX Jewelry</a></h3>
    <p>For this store concept, customers upload a GPS file and that is turned into a custom necklace design.</p>
    <Grid>
      <img src={gpx1} alt="landing page" />
      <img src={gpx2} alt="product page" />
      <img src={gpx3} alt="cart page" />
      <img src={gpx4} alt="checkout page" />
    </Grid>
  </div>
)

export default Examples